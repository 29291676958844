<template>
  <div
    :class="{
      mobile: getIsMobile,
      'is-close-RS': !getIsRSOpen,
      'is-open-RS': getIsRSOpen,
    }"
  >
    <el-dialog
      :visible.sync="show"
      title="Document Users"
      @close="closeSettings"
      top="20px"
      :fullscreen="true"
      v-loading="docUsersLoading"
      element-loading-text="Please wait, we are getting things ready for you."
      element-loading-spinner="pencil"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="addrecipientpage"
    >
      <div>
        <div v-if="docUsersLoading">
          <Loader />
        </div>
        <div class="document-users-order">
          <div
            class="select-recipients"
            v-if="getAllContacts && getAllContacts.data && !docUsersLoading"
          >
            <div class="heading" style="font-family: inter; margin-left: 100px">
              <h3 class="fw-bold fs-24">Add Recipients</h3>
              <i class="el-icon-info"> </i>
              You can drag the list to re-arrange the order
            </div>
            <el-scrollbar
              ref="scrollbar"
              style="min-height: 250px; max-height: 350px"
            >
              <div class="scroll">
                <draggable v-model="users">
                  <div
                    class="el-row-wrapper"
                    :style="`margin-left:100px;margin-top:10px;background-color:#f7f7f7;width:85%;
                            border-left:5px solid ${
                              user && user.color && user.color.length
                                ? user.color
                                : '#F754A2'
                            };               
                            `"
                    v-for="(user, index) in users"
                    :key="index"
                    :id="`user_${index}`"
                  >
                    <el-form :model="selectRecipient" class="demo-form-inline">
                      <el-row :gutter="5">
                        <el-col
                          class="recp-el-col"
                          :xl="{ span: 4 }"
                          :lg="{ span: 4 }"
                          :md="{ span: 8 }"
                          :sm="{ span: 8 }"
                          :xs="{ span: 12 }"
                        >
                          <el-form-item
                            label="Contact Type"
                            prop="contact_type"
                            :required="true"
                          >
                            <el-select
                              v-model="user.contact_type"
                              placeholder="Select contact type"
                              filterable
                              default-first-option
                              clearable
                              @change="onChangeContactType(index)"
                              :disabled="
                                user.type == 'SENDER' ||
                                user.constant_user ||
                                (userHasFields(user.value).length
                                  ? true
                                  : false)
                              "
                            >
                              <template
                                v-if="
                                  !user.email ||
                                  user.email == '' ||
                                  checkmail(user.email) ||
                                  !getSelectedContact(user.email)
                                "
                              >
                                <el-option
                                  v-for="(type, index) in getAllContactTypes"
                                  :key="index"
                                  :value="type._id"
                                  :label="type.name"
                                >
                                  {{ type.name }}
                                </el-option>
                              </template>
                              <template v-else>
                                <el-option
                                  v-for="(type, index) in selectedContactTypes(
                                    user.email,
                                    index
                                  )"
                                  :key="index"
                                  :value="type._id"
                                  :label="type.name"
                                >
                                  {{ type.name }}
                                </el-option>
                              </template>
                            </el-select>
                            <p
                              style="font-size: 12px"
                              class="error"
                              v-if="
                                user &&
                                !user.contact_type &&
                                user.type != 'SENDER' &&
                                is_Submit
                              "
                            >
                              Contact Type is Required
                            </p>
                          </el-form-item>
                        </el-col>
                        <el-col
                          class="recp-el-col"
                          :xl="{ span: 7 }"
                          :lg="{ span: 5 }"
                          :md="{ span: 8 }"
                          :sm="{ span: 8 }"
                          :xs="{ span: 12 }"
                        >
                          <div v-if="user.type != 'SENDER'">
                            <el-form-item
                              class="mb-05"
                              label="Email"
                              prop="email"
                            >
                              <el-select
                                autocomplete="off"
                                class="w-100"
                                placeholder="Search with Email"
                                suffix-icon="el-icon-search"
                                v-model="user.email"
                                clearable
                                filterable
                                @clear="handleClearSelection(index)"
                                :allow-create="true"
                                default-first-option
                                @change="fillrecipientEmailData(index)"
                                @blur="
                                  onEmailLostFocus($event, index, user.email)
                                "
                                :disabled="
                                  user.type == 'SENDER' || user.constant_user
                                "
                                ref="searchSelect"
                                @focus="selectFocus($event, index)"
                                @changing="selectchange"
                                :filter-method="handleEmailFilter"
                              >
                                <el-option
                                  v-for="(
                                    contact, i
                                  ) in getUserTypeContactsFiltered(index)"
                                  :key="i"
                                  :value="contact.email"
                                  :label="contact.email"
                                  :disabled="
                                    fetchCurrentDocumentUsers.indexOf(
                                      contact.email
                                    ) != -1
                                  "
                                >
                                  {{ contact.email }}
                                </el-option>
                              </el-select>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-if="
                                  user &&
                                  (!user.email || !user.email.length) &&
                                  is_Submit
                                "
                              >
                                Email is Required
                              </p>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-else-if="
                                  user &&
                                  user.email &&
                                  user.email.length &&
                                  is_Submit &&
                                  checkmail(user.email)
                                "
                              >
                                Email is not valid
                              </p>
                            </el-form-item>
                          </div>
                          <div v-else>
                            <el-form-item label="Email" class="mb-08">
                              <el-select
                                autocomplete="of"
                                class="w-100"
                                v-model="user.email"
                                placeholder="Search with Email"
                                suffix-icon="el-icon-search"
                                clearable
                                filterable
                                allow-create
                                default-first-option
                                @change="fillrecipientEmailData(index)"
                                :disabled="
                                  user.type == 'SENDER' || user.constant_user
                                "
                              >
                                <el-option
                                  v-for="(
                                    contact, index
                                  ) in getUserTypeContacts"
                                  :key="index"
                                  :value="contact.email"
                                  :disabled="
                                    fetchCurrentDocumentUsers.indexOf(
                                      contact.email
                                    ) != -1
                                  "
                                >
                                  {{ contact.email }}
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col
                          class="recp-el-col"
                          :xl="{ span: 3 }"
                          :lg="{ span: 3 }"
                          :md="{ span: 8 }"
                          :sm="{ span: 8 }"
                          :xs="{ span: 12 }"
                        >
                          <div
                            v-if="user && user.type && user.type != 'SENDER'"
                          >
                            <el-form-item
                              class="mb-05"
                              label="First Name"
                              prop="first_name"
                            >
                              <el-input
                                placeholder="First Name"
                                v-model="user.first_name"
                              >
                              </el-input>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-if="
                                  user &&
                                  (!user.first_name ||
                                    !user.first_name.length) &&
                                  is_Submit
                                "
                              >
                                First Name is Required
                              </p>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-else-if="
                                  user &&
                                  user.first_name &&
                                  user.first_name.length &&
                                  is_Submit &&
                                  checkName(user.first_name)
                                "
                              >
                                First Name is not valid
                              </p>
                            </el-form-item>
                          </div>
                          <div v-else>
                            <el-form-item
                              label="First Name"
                              class="mb-0 mobile-max-width-three"
                              prop="first_name"
                            >
                              <el-input
                                v-model="user.first_name"
                                placeholder="First Name"
                                autocomplete="off"
                              >
                              </el-input>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-if="
                                  user &&
                                  (!user.first_name ||
                                    !user.first_name.length) &&
                                  is_Submit
                                "
                              >
                                First Name is Required
                              </p>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-else-if="
                                  user &&
                                  user.first_name &&
                                  user.first_name.length &&
                                  is_Submit &&
                                  checkName(user.first_name)
                                "
                              >
                                First name should contain atleast '3'
                                characters.
                              </p>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col
                          class="recp-el-col"
                          :xl="{ span: 3 }"
                          :lg="{ span: 3 }"
                          :md="{ span: 6 }"
                          :sm="{ span: 6 }"
                          :xs="{ span: 12 }"
                        >
                          <div v-if="user.type != 'SENDER'">
                            <el-form-item
                              class="mb-05"
                              label="Last Name"
                              prop="last_name"
                            >
                              <el-input
                                v-model="user.last_name"
                                placeholder="Last Name"
                              >
                              </el-input>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-if="
                                  user &&
                                  (!user.last_name || !user.last_name.length) &&
                                  is_Submit
                                "
                              >
                                Last Name is Required
                              </p>
                            </el-form-item>
                          </div>
                          <div v-else>
                            <el-form-item
                              label="Last Name"
                              class="mb-0 mobile-max-width-one"
                              prop="last_name"
                            >
                              <el-input
                                v-model="user.last_name"
                                placeholder="Last Name"
                                autocomplete="off"
                              >
                              </el-input>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-if="
                                  user &&
                                  (!user.last_name || !user.last_name.length) &&
                                  is_Submit
                                "
                              >
                                Last Name is Required
                              </p>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col
                          class="recp-el-col"
                          :xl="{ span: 3 }"
                          :lg="{ span: 3 }"
                          :md="{ span: 6 }"
                          :sm="{ span: 6 }"
                          :xs="{ span: 12 }"
                        >
                          <div v-if="user.type != 'SENDER'">
                            <el-form-item
                              class="mb-05"
                              label="Role"
                              prop="Role"
                            >
                              <el-input
                                v-model="user.name"
                                type="text"
                                placeholder="Role"
                              >
                              </el-input>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-if="
                                  user &&
                                  (!user.name || !user.name.length) &&
                                  is_Submit &&
                                  user.type != 'SENDER'
                                "
                              >
                                Role is Required
                              </p>
                              <p
                                style="font-size: 12px"
                                class="error"
                                v-else-if="
                                  !checkRoleDuplicate(
                                    user && user.name ? user.name : '',
                                    index
                                  )
                                "
                              >
                                Role can't be same
                              </p>
                            </el-form-item>
                          </div>
                          <div v-else>
                            <el-form-item
                              label="Role"
                              class="mb-0 mobile-max-width-one"
                              prop="role"
                            >
                              <el-input
                                v-model="user.name"
                                type="text"
                                placeholder="Role"
                                :disabled="
                                  user.type == 'SENDER' || user.constant_user
                                "
                              ></el-input>
                            </el-form-item>
                          </div>
                          <!-- </div> -->
                        </el-col>
                        <el-col
                          class="recp-el-col"
                          :xl="{ span: 2 }"
                          :lg="{ span: 4 }"
                          :md="{ span: 6 }"
                          :sm="{ span: 6 }"
                          :xs="{ span: 12 }"
                        >
                          <!-- <div class="d-flex"> -->
                          <el-form-item
                            label="Signer Type"
                            class="mb-0 mobile-max-width-one"
                            prop="signer_type"
                            v-if="user && user.user_type"
                          >
                            <el-select
                              v-model="user.user_type"
                              placeholder="Select Signer Type"
                            >
                              <el-option
                                v-for="(type, index) in [
                                  'SIGNER',
                                  'CC',
                                  'APPROVER',
                                ]"
                                :key="index"
                                :value="type"
                                :label="type"
                                :disabled="
                                  type == 'CC' &&
                                  user &&
                                  (fetchSignUsers.indexOf(user.value) != -1 ||
                                    users.length == 1)
                                "
                              >
                                {{ type }}
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col
                          class="recp-el-col"
                          :xl="{ span: 2 }"
                          :lg="{ span: 2 }"
                          :md="{ span: 6 }"
                          :sm="{ span: 6 }"
                          :xs="{ span: 12 }"
                        >
                          <!-- <el-form-item class="mb-10 w-100 mobile-max-width-one" prop="update">-->
                          <el-link
                            v-if="user.type != 'SENDER'"
                            @click="deleteReceiver(index)"
                            :underline="false"
                          >
                            <i
                              class="el-icon-circle-close"
                              style="margin-top: 40px; margin-left: 15px"
                            ></i>
                          </el-link>
                          <span v-else>&nbsp;</span>
                        </el-col>
                        <!--Add more fields-->
                        <el-col v-if="!getIsMobile">
                          <el-collapse v-model="activeAddMore">
                            <el-collapse-item
                              d-flex
                              style="margin-left: 2px; margin-top: -20px"
                              class="AddMore"
                              v-if="user.type != 'SENDER'"
                              :name="index"
                            >
                              <p
                                slot="title"
                                style="font-size: 14px"
                                @click="handleAddMoreFields"
                              >
                                Add More Fields
                                <el-tooltip
                                  placeholder="top-start"
                                  content="You can add additional fields from here."
                                  color="#FFFFFF"
                                >
                                  <i class="el-icon-arrow-down"></i
                                ></el-tooltip>
                              </p>

                              <div class="main" v-if="!getIsMobile">
                                <div class="addstyle">
                                  <el-form-item
                                    label="Middle Name"
                                    prop="middle_name"
                                    class="mb-05"
                                    style="margin-left: 10px"
                                  >
                                    <el-input
                                      style="width: 180px"
                                      v-model="user.middle_name"
                                      type="text"
                                      placeholder="middle_name"
                                    ></el-input>
                                    <p
                                      class="error"
                                      v-if="
                                        user &&
                                        parseInt(user.middle_name) &&
                                        is_Submit
                                      "
                                    >
                                      Please enter Characters
                                    </p>
                                  </el-form-item>
                                </div>
                                <div class="addstyle">
                                  <el-form-item
                                    class="mb-05"
                                    style="margin-left: 10px"
                                    label="Address"
                                    prop="address"
                                  >
                                    <br />
                                    <el-input
                                      style="width: 180px; float: left"
                                      v-model="user.address"
                                      type="text"
                                      placeholder="Address"
                                    ></el-input>
                                  </el-form-item>
                                </div>
                                <div class="addstyle">
                                  <el-form-item label="Title" prop="title">
                                    <el-input
                                      style="width: 180px"
                                      v-model="user.title"
                                      type="text"
                                      placeholder="Title"
                                    ></el-input>
                                    <p
                                      class="error"
                                      v-if="
                                        user &&
                                        parseInt(user.title) &&
                                        is_Submit
                                      "
                                    >
                                      Please enter Characters
                                    </p>
                                  </el-form-item>
                                </div>
                                <div
                                  class="addstyle"
                                  v-if="
                                    user.contact_type &&
                                    user.contact_type.length &&
                                    getCurrentUserContactType(user.contact_type)
                                  "
                                >
                                  <el-form-item
                                    label="Company Name"
                                    prop="company"
                                    v-if="
                                      getCurrentUserContactType(
                                        user.contact_type
                                      ) &&
                                      getCurrentUserContactType(
                                        user.contact_type
                                      ).type == 'STANDARD' &&
                                      getCurrentUserContactType(
                                        user.contact_type
                                      ).slug == 'receiver'
                                    "
                                  >
                                    <el-input
                                      style="width: 180px"
                                      type="text"
                                      v-model="user.company_name"
                                      placeholder="Company name"
                                    ></el-input>
                                    <p
                                      class="error"
                                      v-if="
                                        user &&
                                        parseInt(user.company_name) &&
                                        is_Submit
                                      "
                                    >
                                      Please enter Characters
                                    </p>
                                  </el-form-item>
                                  <el-form-item
                                    label="Company Name"
                                    prop="company"
                                    v-else-if="
                                      (getCurrentUserContactType(
                                        user.contact_type
                                      ) &&
                                        getCurrentUserContactType(
                                          user.contact_type
                                        ).entity_type == 'BUSINESS' &&
                                        user.entity_data_id &&
                                        user.entity_data_id
                                          .entity_prime_data) ||
                                      (getCurrentUserContactType(
                                        user.contact_type
                                      ).entity_type == 'INDIVIDUAL' &&
                                        user.entity_data_id &&
                                        user.entity_data_id.entity_prime_data)
                                    "
                                  >
                                    <el-input
                                      type="text"
                                      v-if="
                                        user.entity_data_id &&
                                        user.entity_data_id.entity_prime_data
                                      "
                                      :value="
                                        getPrimaryValue(
                                          user.entity_data_id.entity_prime_data,
                                          getCurrentUserContactType(
                                            user.contact_type
                                          ).entity_type
                                        )
                                      "
                                      placeholder="Company name"
                                      disabled
                                    ></el-input>
                                  </el-form-item>
                                </div>

                                <div class="addstyle">
                                  <el-form-item
                                    label="Phone"
                                    prop="phone"
                                    :model="ruleForm"
                                    :rules="rules"
                                    ref="ruleForm"
                                  >
                                    <br />
                                    <el-input
                                      style="width: 200px; float: left"
                                      v-model="user.phone"
                                      maxlength="15"
                                      type="text"
                                      placeholder="Phone"
                                    ></el-input>
                                    <p
                                      style="font-size: 12px"
                                      class="error"
                                      v-if="
                                        user &&
                                        user.phone &&
                                        checkphone(user.phone) &&
                                        is_Submit
                                      "
                                    >
                                      Invalid phone number
                                    </p>
                                  </el-form-item>
                                </div>
                                <!-- <el-tooltip
                                  content="Check 'Update Contact' to update this data in contacts."
                                >
                                  <el-checkbox
                                    v-model="user.is_update_contact"
                                    class="checkbox"
                                    >Update Contact
                                  </el-checkbox>
                                </el-tooltip> -->
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </draggable>
              </div>
            </el-scrollbar>
            <div>
              <el-button
                style="margin-left: 100px; margin-top: 10px"
                @click="addReceiver"
              >
                <img src="@/assets/img/Add Recipients.svg" alt="icon" />
                <span
                  style="
                    margin-left: 8px;
                    font-weight: 500;
                    color: #000000;
                    font-family: inter;
                  "
                  >Add Recipient</span
                >
              </el-button>
              <el-col>
                <el-divider class="mt-1"></el-divider>
                <div>
                  <el-checkbox
                    v-model="enforceSignatureOrderValue"
                    style="margin-left: 98px"
                  >
                    Enforce Signature Order
                  </el-checkbox>
                  <el-tooltip
                    effect="dark"
                    :content="enforceSignatureOrderValue ? '' : ''"
                    placement="top-start"
                  >
                    <el-checkbox
                      v-model="isAnyoneCanApproveValue"
                      v-if="checkHasMoreApprovers"
                      :disabled="checkAllApproverPosition"
                      >Any approver user can approve</el-checkbox
                    >
                  </el-tooltip>
                </div>
                <el-divider class="mt-1"></el-divider>
              </el-col>
            </div>

            <el-row style="float: right">
              <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="8">
                <el-button
                  plain
                  @click="closeSettings"
                  class="fs"
                  style="width: 90px; border-radius: 4px; margin-right: 75px"
                >
                  Cancel
                </el-button>
              </el-col>
              <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="8">
                <el-button
                  type="danger"
                  style="
                    border-radius: 4px;
                    margin-left: 30px;
                    color: #ffffff;
                    width: 110px;
                  "
                  @click="saveUsers"
                >
                  Save Users
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      style="width: 50%"
      title="Add Contact Type"
      :visible.sync="showAddGroupDialog"
      class="outersize-confirm-type-two"
      :before-close="eraseContactType"
    >
      <el-form>
        <div>
          <el-form-item class="mb" label="Contact Type">
            <el-form-item>
              <el-input v-model="contact_type" placeholder="Enter contact type">
              </el-input>
            </el-form-item>
          </el-form-item>

          <el-form-item class="text-center mt-20">
            <el-button @click="eraseContactType()"> Cancel </el-button>
            <el-button
              type="primary"
              @click="addContactType()"
              :loading="addContactTypeLoading"
              :disabled="!contact_type.trim()"
              class="px-3"
              ><i class="el-icon-check"></i> Submit
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
    <AddContactTypes
      v-on:newContactTypeAdded="contactTypeDialogVisible = false"
      v-if="contactTypeDialogVisible"
    >
    </AddContactTypes>
    <el-dialog
      :visible.sync="showAddContact"
      :before-close="checkContactTypes"
      title="Add contact"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="getIsMobile ? '100%' : ''"
    >
      <div>
        <AddContact
          v-on:close="closeAddContact"
          :contactData="newUser"
          :selectedContact="selectedContact"
          :contactTypes="contactTypes"
          :checkIsDisabled="checkIsDisabled"
          v-if="showAddContact"
        >
        </AddContact>
      </div>
    </el-dialog>
  </div>
</template>

<script src="https://unpkg.com/vue"></script>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import UserHelper from "@/mixins/UserHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
//import DIV from "../../templates/formComponents/DIV.vue";
import AddContactTypes from "@/components/contacts/AddContactTypes";
// import AddContact from "@/components/contacts/AddContact";
import Loader from "@/views/loader.vue";
import { bus } from "../../../main";
//import { container } from 'element-ui';
export default {
  name: "configureDocuments-ConfigureDocumentUsers",
  props: [
    "documentUsers",
    "enforceSignatureOrder",
    "isSubmit",
    "allElements",
    "isTemplate",
    "isAnyoneCanApprove",
    "openCk",
    "editorFields",
    "editorContent",
  ],
  components: {
    draggable,
    AddContactTypes,
    AddContact: () => import("./ConfigureContactDetails"),
    Loader,
  },
  mixins: [UserHelper, DocumentsHelper],
  data() {
    var validateEmail = (rule, value, callback) => {
      var emailReg =
        /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      let emails = this.getAllContacts.data.map((user) => user.email);
      if (value === "") {
        callback(new Error("Email is Required"));
      } else if (!emailReg.test(value)) {
        callback(new Error("Email is not valid"));
      } else if (emails.indexOf(value) != -1) {
        callback(new Error("Email already existed"));
      } else {
        callback();
      }
      var checkPhone = (rule, value, callback) => {
        var phoneReg =
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (!Number.isInteger(+value)) {
          callback(new Error("Please enter a numeric value"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("The phone number format is incorrect"));
          }
        }
      };
    };
    return {
      userTypeContactsFiltered: [],
      is_update_contact: false,
      rules: {
        phone: [
          {
            //validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      showAddGroupDialog: false,
      loading: false,
      contactTypeDialogVisible: false,
      show: true,
      showNewUserForm: false,
      ruleForm: {
        phone: 13833334444,
      },
      contact_type: "",
      users: [
        {
          email: "",
          first_name: "",
          last_name: "",
          e_signature_required: false,
          has_approval_access: false,
          value: "SENDER",
          name: "SENDER",
          default: true,
          phone: "",
          address: "",
          company_name: "",
          e_signature_order: 0,
          is_cc: false,
          user_type: "SIGNER",
          contact_type: "User",
        },
      ],
      newUser: {
        email: "",
        first_name: "",
        e_signature_required: "",
        has_approval_access: false,
        last_name: "",
        phone: "",
        address: "",
        company_name: "",
        is_cc: false,
        name: "",
        user_type: "SIGNER",
        contact_type: "",
        contact: "",
        title: "",
      },
      customeUsers: [],
      refreshSignatureOrder: false,
      enforceSignatureOrderValue: true,
      isAnyoneCanApproveValue: false,
      selectedUser: null,
      user: null,
      selectedUserIndex: -1,
      selectRecipient: {
        email: "",
        user_type: "SIGNER",
        password_protected: true,
        is_cc: false,
        name: "",
        contact_type: "",
      },
      docUsersLoading: false,
      addContactTypeLoading: false,
      isSignerInUsers: false,
      isApprovalUsers: false,
      isExecute: true,
      enabled: true,
      isAdd: false,
      is_Submit: false,
      resetUsers: [],
      isOpen: false,
      activeAddMore: [],
      selectedUserForContactType: -1,
      showAddContact: false,
      selectedContact: null,
      elements: [],
      alertShown: false,
      newContactCheck: false,
      contactTypes: [],
      checkIsDisabled: true,
      filterVal: "",
      ckEditorFields: [],
      ckEditorContent: "",
      openEditor: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("contacts", [
      "getAllContacts",
      "getCreateContactStatus",
      "getUpdateContactStatus",
      "getContactErrors",
      "getCreateContactData",
      "getContactTypeCreateStatus",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("contactTypes", [
      "getAllContactTypes",
      "getContactTypeCreateStatus",
    ]),
    ...mapGetters("documents", [
      "getDocumentUsersStatus",
      "getEmployeeDocumentUsers",
      "getDraftDocumentUpdateStatus",
      "getDraftDocumentUpdateErrors",
    ]),
    ...mapGetters("configurableDocuments", [
      "getDocumentUsers",
      "getConfigurableDocumentUpdateStatus",
    ]),
    ...mapGetters("entities", ["getCompanyEntityDetails"]),
    ...mapGetters("company", ["getCompanyDetails", "getListOfCompanies"]),
    checkmail() {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (mail) => !re.test(mail);
    },
    checkName() {
      let re = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].?\d|\d.?[A-Za-z]){1,}/;
      return (first_name) => !re.test(first_name);
    },
    checkphone() {
      let re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      //if(user.phone.length){
      return (phone) => !re.test(phone);
      //}
    },
    fetchSignUsers() {
      return this.allElements.flatMap(
        (element) => element.filled_by || element.selected_user
      );
    },
    fetchHasFieldsUses() {
      return this.allElements.flatMap((element) => element.filled_by);
    },
    checkAllApproverPosition() {
      if (!this.enforceSignatureOrderValue) {
        return false;
      }
      let approvalUsers = [];
      this.users.forEach((user, index) => {
        if (user.user_type == "APPROVER") {
          approvalUsers.push(index);
        }
      });
      if (approvalUsers && approvalUsers.length) {
        approvalUsers = approvalUsers.sort((a, b) => a - b);
        let checkApproversPosistion = false;
        for (let i = 0; i < approvalUsers.length - 1; i++) {
          if (approvalUsers.indexOf(approvalUsers[i] + 1) == -1) {
            if (this.isAnyoneCanApproveValue) {
              this.$message.warning(
                "If enforce signature is on all approvers must be together then only anyone can approve will be available"
              );
              // this.isAnyoneCanApproveValue = false;
              this.changeAnyonecanApprove();
            }
            checkApproversPosistion = true;
          }
        }
        return checkApproversPosistion;
      }
      return false;
    },
    checkHasMoreApprovers() {
      let approvalUsers = this.users.filter(
        (user) => user.user_type == "APPROVER"
      );
      if (approvalUsers && approvalUsers.length && approvalUsers.length > 1) {
        return true;
      }
      return false;
    },
    checkNonFillFields() {
      // if (this.isSubmit) {
      let users = this.users.filter(
        (user) => !user.first_name || !user.last_name || !user.email
      );
      // }
      return users && users.length ? true : false;
    },

    existedSignatureDate() {
      return (dateField) =>
        this.signaturesConfig.find(
          (user) => user.e_signature_date_field == dateField.key
        )
          ? true
          : false;
    },
    fetchCurrentDocumentUsers() {
      return this.users.map((user) => user.email);
    },
    checkAddReceiverDisable() {
      if (
        !this.newUser.email ||
        !this.newUser.first_name ||
        !this.newUser.last_name
      ) {
        return true;
      }
      return false;
    },
    getSelectedContact() {
      return (email) =>
        (this.getAllContacts.data || []).find(
          (e) => e && e.email && e.email.toLowerCase() == email.toLowerCase()
        );
    },

    getUserTypeContacts() {
      if (this.getAllContacts && this.getAllContacts.data) {
        if (this.selectRecipient.contact_type) {
          return this.getAllContacts.data.filter(
            (contact) =>
              contact.contact_type &&
              contact.contact_type.name &&
              contact.contact_type.name == this.selectRecipient.contact_type
          );
        } else {
          return this.getAllContacts.data;
        }
      }
      return [];
    },
  },

  async mounted() {
    this.is_Submit = false;
    this.docUsersLoading = true;
    //this.$store.commit("contacts/setContactErrors", null, { root: true });
    await Promise.all([
      this.$store.dispatch("contacts/fetchAllContacts", {
        get_all: true,
        type: "CONTACT",
      }),
      this.$store.dispatch("contactTypes/fetchAllContactTypes", {
        get_all: true,
        from_contacts: true,
        type: "CONTACT_TYPE",
      }),
    ]);
    if (this.$route.name == "edit-configure-document-image") {
      this.setConfigDocumentUsers();
    } else {
      this.setEmployeeDocumentUsers();
    }
    if (this.allElements && this.allElements.length) {
      this.elements = this.allElements;
    }
    if (
      this.editorFields &&
      this.editorFields.length &&
      this.openCk &&
      this.editorContent
    ) {
      this.ckEditorFields = this.editorFields;
      this.openEditor = this.openCk;
      this.ckEditorContent = this.editorContent;
    }
    // this.fetchAllContactTypesData();
  },
  watch: {
    getUserTypeContacts: {
      handler(newAllOptions) {
        this.userTypeContactsFiltered = [...newAllOptions];
      },
      immediate: true,
    },
  },
  methods: {
    handleClearSelection(index) {
      this.filterVal = "";
    },
    selectedContactTypes(email, index) {
      let result = [];
      if (email) {
        let contact = this.getSelectedContact(email);
        let ids = [];
        if (contact) {
          if (contact?.contact_types && contact.contact_types.length) {
            ids = contact?.contact_types.flatMap(
              (e) => e?.contact_type?._id || e.contact_type
            );
          } else if (contact?.contact_type) {
            ids = [
              contact?.contact_type?._id
                ? contact?.contact_type?._id
                : contact?.contact_type,
            ];
          }
          result = (this.getAllContactTypes || []).filter(
            (e) => ids.indexOf(e._id) !== -1
          );
          if (this.users[index] && this.users[index].contact_type) {
            let found = result.findIndex(
              (e) => e._id == this.users[index].contact_type
            );
            if (found == -1) {
              let contactType = (this.getAllContactTypes || []).find(
                (e) => e._id == this.users[index].contact_type
              );
              if (contactType) {
                result.push(contactType);
              }
            }
          }
        }
      }
      if (
        this.getStandardContactType?._id &&
        (!result.length ||
          result.findIndex((e) => e._id == this.getStandardContactType?._id) ==
            -1)
      ) {
        result.push(this.getStandardContactType);
      }
      return result;
    },
    changeAnyonecanApprove() {
      this.isAnyoneCanApproveValue = false;
    },
    getCurrentUserContactType(id) {
      if (id && this.getAllContactTypes && this.getAllContactTypes.length) {
        return this.getAllContactTypes.find((e) => id && e._id == id);
      }
      return {};
    },
    getPrimaryValue(data, type) {
      let value = "";
      if (type == "INDIVIDUAL") {
        value = data["name"] ? data["name"] : "";
      } else {
        if (data && Object.keys(data) && Object.keys(data)[0]) {
          value = data[Object.keys(data)[0]] ? data[Object.keys(data)[0]] : "";
        }
      }
      return value;
    },
    //get selected user fields
    userHasFields(value) {
      return this.elements.filter(
        (e) => (e.entity_id && e.filled_by == value) || e.selected_user == value
      );
    },
    getUserTypeContactsFiltered(index) {
      if (this.users[index].contact_type) {
        if (
          this.getStandardContactType?._id == this.users[index].contact_type
        ) {
          return (this.getAllContacts.data || []).filter(
            (e) =>
              !this.filterVal ||
              (this.filterVal && e.email.includes(this.filterVal))
          );
        }
        return (this.getAllContacts.data || []).filter((e) => {
          let search =
            !this.filterVal ||
            (this.filterVal && e.email.includes(this.filterVal));
          if (e?.contact_types && e.contact_types.length) {
            let found = e.contact_types.findIndex(
              (el) =>
                (el?.contact_type?._id &&
                  el.contact_type._id == this.users[index].contact_type) ||
                el.contact_type == this.users[index].contact_type
            );
            return found == -1 ? false : search ? true : false;
          } else {
            return (
              e.contact_type &&
              e.contact_type._id == this.users[index].contact_type &&
              search
            );
          }
        });
      } else {
        if (this.getAllContacts && this.getAllContacts.data) {
          return this.getAllContacts.data.filter(
            (e) =>
              !this.filterVal ||
              (this.filterVal && e.email.includes(this.filterVal))
          );
        }
        return [];
      }
    },
    editContact(index) {
      this.selectedContact = null;
      let id = this.users[index].contact;
      if (!id) {
        let contact = this.getAllContacts.data.find(
          (e) => e.email == this.users[index].email
        );
        id = contact._id;
      }
      this.selectedContact = id;
      this.selectedUserIndex = -1;
      this.selectedUserIndex = index;
      this.showAddContact = true;
    },
    checkContactTypes() {
      this.filterVal = "";
      if (!this.users[this.selectedUserIndex].contact) {
        this.users[this.selectedUserIndex].email = "";
        this.users[this.selectedUserIndex].first_name = "";
        this.users[this.selectedUserIndex].last_name = "";
      } else {
        let contact = this.getAllContacts.data.find(
          (e) => e._id == this.users[this.selectedUserIndex].contact
        );
        if (contact && contact.email) {
          this.users[this.selectedUserIndex].email = contact.email;
        }
      }
      this.setAllContacts(null);
      this.selectedUserIndex = -1;
      this.showAddContact = false;
    },
    async closeAddContact({
      createContact,
      data,
      is_update,
      is_creating_new_contact,
    }) {
      this.docUsersLoading = true;
      this.filterVal = "";
      if (!createContact) {
        this.users[this.selectedUserIndex].email = "";
        this.users[this.selectedUserIndex].first_name = "";
        this.users[this.selectedUserIndex].last_name = "";
        this.docUsersLoading = false;
      } else {
        const userHasFields = this.userHasFields(
          this.users[this.selectedUserIndex].value
        );
        let contactTypes = this.getContactTypesFromContact(data);
        if (contactTypes && contactTypes.length) {
          let id = this.users[this.selectedUserIndex].contact_type
            ? this.users[this.selectedUserIndex].contact_type._id
              ? this.users[this.selectedUserIndex].contact_type._id
              : this.users[this.selectedUserIndex].contact_type
            : "";
          let contactType = (contactTypes || []).find(
            (el) =>
              (el?.contact_type?._id && el.contact_type._id == id) ||
              el.contact_type == id
          );
          if (
            !this.users[this.selectedUserIndex].contact_type &&
            !contactType
          ) {
            contactType = contactTypes[0];
          }
          if (contactType) {
            await this.$store.dispatch("contacts/fetchAllContacts", {
              get_all: true,
              type: "CONTACT",
            });
            let savedContact = this.getAllContacts.data.find(
              (e) => e.email.toLowerCase() == data.email.toLowerCase()
            );
            let account_data_id = (savedContact.contact_types || []).find(
              (el) =>
                (el?.contact_type?._id &&
                  el.contact_type._id ==
                    this.users[this.selectedUserIndex].contact_type) ||
                el.contact_type ==
                  this.users[this.selectedUserIndex].contact_type
            );
            this.users[this.selectedUserIndex].contact = data._id;
            this.users[this.selectedUserIndex].contact_id = data._id;
            if (
              (is_update || is_creating_new_contact) &&
              this.users[this.selectedUserIndex].contact_type !=
                contactType.contact_type
            ) {
              if (!this.users[this.selectedUserIndex].contact_type) {
                this.users[this.selectedUserIndex].contact_type =
                  contactType.contact_type;
              }
              this.fillRoleNameData(this.selectedUserIndex, true);
            }
            this.users[this.selectedUserIndex].phone = data.phone_number;
            if (data?.contact_types) {
              delete data.contact_types;
            }
            if (data?.contact_type) {
              delete data.contact_type;
            }
            if (account_data_id?.account_data_id) {
              data = {
                ...data,
                ...{ entity_data_id: account_data_id?.account_data_id },
              };
            }
            this.users[this.selectedUserIndex] = {
              ...this.users[this.selectedUserIndex],
              ...{
                ...data,
              },
            };
            let tempUsers = [];
            await setTimeout(() => {
              tempUsers = this.users;
              this.users = [];
              this.users = [...tempUsers];
              this.docUsersLoading = false;
            }, 1000);
          } else {
            if (contactType && contactType.name) {
              data.contact_type = contactType.name;
            }
            this.contactTypeAlertMethod(this.selectedUserIndex, data);
            return;
          }
        } else {
          this.$notify.error({
            title: "Error",
            message: "Contact types not selected!",
          });
        }
      }
      this.newContactCheck = false;
      this.docUsersLoading = false;
      this.selectedUserIndex = -1;
      this.setAllContacts(null);
      this.showAddContact = false;
    },
    async fetchAllContactTypesData() {
      try {
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          from_contacts: true,
          type: "CONTACT_TYPE",
        });
      } catch (err) {}
    },
    addNewContactType(index) {
      this.selectedUserForContactType = index;
      this.showAddGroupDialog = true;
    },
    visibleChange(idx) {
      if (
        this.$refs &&
        this.$refs.searchSelect[idx] &&
        this.$refs.searchSelect[idx].$children[0] &&
        this.$refs.searchSelect[idx].$children[0].$refs
      ) {
      }
    },
    handleAddMoreFields() {
      setTimeout(() => this.scrollToEnd(), 500);
    },
    scrollToEnd() {
      let scrollbarEl = this.$refs.scrollbar.wrap;
      scrollbarEl.scrollTop = scrollbarEl.scrollHeight;
    },
    hasDeleteAccess(user) {
      if (user && user.value && this.allElements) {
        let element = this.allElements.find((element) => {
          if (
            (element &&
              element.selected_user &&
              element.selected_user == user.value) ||
            (element.filled_by && element.filled_by == user.value)
          ) {
            return element;
          }
        });
        if (element) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    async setEmployeeDocumentUsers() {
      await this.$store.dispatch("documents/fetchDocumentUsers", {
        document_id: this.$route.params.employee_document_id,
      });
      this.users = [];
      let users = [];
      if (this.getDocumentUsersStatus) {
        users = [...this.getEmployeeDocumentUsers.document_users];
        this.enforceSignatureOrderValue =
          this.getEmployeeDocumentUsers.enforce_signature_order;
        this.isAnyoneCanApproveValue =
          this.getEmployeeDocumentUsers.is_anyone_can_approve;
      } else {
        users = [...this.documentUsers];
      }
      this.users = users.map((user) => {
        if (user.type == "SENDER") {
          let companyName = "";
          if (
            this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
            this.getActiveWorkspace.company_id &&
            this.getActiveWorkspace.company_id != "0"
          ) {
            companyName =
              this.getCompanyDetails && this.getCompanyDetails.legal_name != ""
                ? this.getCompanyDetails.legal_name
                : this.getCompanyDetails && this.getCompanyDetails.name
                ? this.getCompanyDetails.name
                : "";
          } else {
            companyName =
              this.getAuthenticatedUser &&
              this.getAuthenticatedUser.company_name;
          }
          user.email = this.getAuthenticatedUser.email;
          user.first_name = this.getAuthenticatedUser.first_name;
          user.last_name = this.getAuthenticatedUser.last_name;
          user.company_name = companyName;
          user.phone = this.getAuthenticatedUser.phone;
          user.address =
            this.getActiveWorkspace.company_id &&
            this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
            this.getActiveWorkspace.company_id != "0"
              ? this.getCompanyDetails && this.getCompanyDetails.address
                ? this.getCompanyDetails.address
                : this.getAuthenticatedUser.address
              : this.getAuthenticatedUser.address;
          user.is_cc = false;
          if (!user.user_type) {
            user.user_type = "SIGNER";
          }
        } else if (user.contact_id) {
          let contactUser = user.contact_id;
          if (contactUser.phone_number) {
            contactUser["phone"] = contactUser.phone_number;
          }
          contactUser = this.getBasicUserInfo(contactUser);
          user = { ...user, ...contactUser };
        }

        if (user.contact_type) {
          let typeData = this.getAllContactTypes.filter(
            (type) => type._id == user.contact_type
          );
          if (typeData && typeData.length) {
            user.temp_contact_type =
              typeData[0] && typeData[0]["name"] ? typeData[0]["name"] : "";
          }
        }
        if (this.getAllContactTypes && this.getAllContactTypes.length == 1) {
          this.selectRecipient.contact_type = this.getAllContactTypes[0].name;
          this.selectRecipient.name =
            this.getAllContactTypes[0].name + "-" + this.users.length;
        }
        if (user.type != "SENDER" && !user.user_type) {
          user.user_type = "SIGNER";
        }
        this.resetUsers.push(users);
        return user;
      });

      this.docUsersLoading = false;
    },
    getBasicUserInfo(data) {
      return {
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        email: data.email,
        phone: data.phone,
        address: data.address,
        title: data.title,
      };
    },
    async setConfigDocumentUsers() {
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigDocumentUsers",
        {
          id: this.$route.params.configurable_document_id,
        }
      );
      await this.$store.dispatch("entities/fetchCompanyEntityDetails");
      this.users = [];
      let users = [];
      if (this.getDocumentUsers && this.getDocumentUsers.document_users) {
        users = [...this.getDocumentUsers.document_users];
        this.enforceSignatureOrderValue =
          this.getDocumentUsers.enforce_signature_order;
        this.isAnyoneCanApproveValue =
          this.getDocumentUsers.is_anyone_can_approve;
        if (!users.length) {
          let companyName = "";
          if (
            this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
            this.getActiveWorkspace.company_id &&
            this.getActiveWorkspace.company_id != "0"
          ) {
            companyName =
              this.getCompanyDetails && this.getCompanyDetails.legal_name != ""
                ? this.getCompanyDetails.legal_name
                : this.getCompanyDetails && this.getCompanyDetails.name
                ? this.getCompanyDetails.name
                : "";
          } else {
            companyName = this.getAuthenticatedUser.company_name;
          }
          users.push({
            default: true,
            email: this.getAuthenticatedUser.email,
            first_name: this.getAuthenticatedUser.first_name,
            last_name: this.getAuthenticatedUser.last_name,
            address:
              this.getActiveWorkspace &&
              this.getActiveWorkspace.company_id &&
              this.getCompanyDetails &&
              this.getAuthenticatedUser &&
              this.getActiveWorkspace.plan_type &&
              this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
              this.getActiveWorkspace.company_id != "0"
                ? this.getCompanyDetails.address
                : this.getAuthenticatedUser.address,
            phone: this.getAuthenticatedUser.phone,
            company_name: companyName,
            title: this.getAuthenticatedUser.title,
            e_signature_required: true,
            value: "SENDER",
            name: "SENDER",
            type: "SENDER",
            e_signature_order: 0,
            e_signature_fields: [],
            e_signature_date_fields: [],
            has_approval_access: false,
            is_cc: false,
            user_type: "SIGNER",
            color: "#F754A2",
          });
          let params = {
            document_users: users,
            id: this.$route.params.configurable_document_id,
          };
          await this.$store.dispatch(
            "configurableDocuments/updateConfigurableDocument",
            params
          );
        }
      } else {
        users = [...this.documentUsers];
      }
      this.users = users.map((user) => {
        if (user.type == "SENDER") {
          let companyName = "";
          if (
            this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
            this.getActiveWorkspace.company_id &&
            this.getActiveWorkspace.company_id != "0"
          ) {
            companyName =
              this.getCompanyDetails && this.getCompanyDetails.legal_name != ""
                ? this.getCompanyDetails.legal_name
                : this.getCompanyDetails && this.getCompanyDetails.name
                ? this.getCompanyDetails.name
                : "";
          } else {
            companyName = this.getAuthenticatedUser.company_name;
          }
          user.email = this.getAuthenticatedUser.email;
          user.first_name = this.getAuthenticatedUser.first_name;
          user.last_name = this.getAuthenticatedUser.last_name;
          user.company_name = companyName;
          user.entity_data_id =
            this.getCompanyEntityDetails &&
            this.getCompanyEntityDetails.entity_data_details &&
            this.getCompanyEntityDetails.entity_data_details[0] &&
            this.getCompanyEntityDetails.entity_data_details[0]._id
              ? this.getCompanyEntityDetails.entity_data_details[0]._id
              : null;
          user.phone = this.getAuthenticatedUser.phone;
          user.address =
            this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
            this.getActiveWorkspace.company_id &&
            this.getActiveWorkspace.company_id != "0"
              ? this.getCompanyDetails && this.getCompanyDetails.address
                ? this.getCompanyDetails.address
                : this.getAuthenticatedUser.address
              : this.getAuthenticatedUser.address;
          user.is_cc = false;
          if (!user.user_type) {
            user.user_type = "SIGNER";
          }
        }

        if (user.contact_type) {
          let typeData = this.getAllContactTypes.filter(
            (type) => type._id == user.contact_type
          );
          if (typeData && typeData.length) {
            user.temp_contact_type =
              typeData[0] && typeData[0]["name"] ? typeData[0]["name"] : "";
          }
        }
        if (this.getAllContactTypes.length == 1) {
          this.selectRecipient.contact_type = this.getAllContactTypes[0].name;
          this.selectRecipient.name =
            this.getAllContactTypes[0].name + "-" + this.users.length;
        }
        if (user.type != "SENDER" && !user.user_type) {
          user.user_type = "SIGNER";
        }
        this.resetUsers.push(users);
        return user;
      });
      setTimeout(() => {
        this.docUsersLoading = false;
      }, 4000);
    },
    async eraseContactType() {
      this.contact_type = "";
      this.showAddGroupDialog = false;
      this.selectedUserForContactType = -1;
    },
    async addContactType() {
      try {
        this.addContactTypeLoading = true;
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          from_contacts: true,
          type: "CONTACT_TYPE",
        });
        if (this.getAllContactTypes) {
          let exst_type = this.getAllContactTypes.find((el) => {
            if (
              el &&
              el.name &&
              this.contact_type &&
              el.name.toLowerCase() == this.contact_type.toLowerCase()
            ) {
              return el;
            }
          });
          if (exst_type) {
            this.$notify.error({
              title: "Error",
              message: "Contact Type with this name is already exist!",
            });
            this.addContactTypeLoading = false;
            return;
          }
        }
        await this.$store.dispatch("contactTypes/createContactType", {
          name: this.contact_type,
        });
        // this.users[index].contact_type = this.contact_type;
        if (this.getContactTypeCreateStatus) {
          this.addContactTypeLoading = false;
          this.successNotify("Contact type created Successfully");
          this.users[this.selectedUserForContactType].temp_contact_type =
            this.contact_type;
          this.onChangeContactType(this.selectedUserForContactType);
          this.$emit("newContactTypeAdded", true);
        } else {
          this.$notify.error({
            title: "Error",
            message: "Contact Type with this name is already exist!",
          });
          this.addContactTypeLoading = false;
        }
        this.showAddGroupDialog = false;
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          from_contacts: true,
          type: "CONTACT_TYPE",
        });
        //this.users[index].contact_type = this.getAllContactTypes[0]._id;
        this.modalLoading = false;
        // this.loading = false;
        this.contact_type = "";
      } catch (err) {
        this.modalLoading = false;
      }
    },
    createContactForm() {
      this.showNewUserForm = true;
      this.newUser.contact_type = this.selectRecipient.contact_type;
    },

    updatedAccountDataId(index) {
      let selectedContact = this.getSelectedContact(this.users[index].email);
      if (selectedContact) {
        let accountDataId = "";
        if (
          selectedContact.contact_types &&
          selectedContact.contact_types.length
        ) {
          let contactTypeData = selectedContact.contact_types.find(
            (e) =>
              (e?.contact_type?._id &&
                e.contact_type._id.toString() ==
                  this.users[index].contact_type.toString()) ||
              e?.contact_type == this.users[index].contact_type
          );
          if (contactTypeData?.account_data_id) {
            accountDataId = contactTypeData.account_data_id;
          }
        } else if (selectedContact.contact_type) {
          accountDataId = selectedContact?.account_data_id
            ? selectedContact?.account_data_id?._id
              ? selectedContact?.account_data_id?._id
              : selectedContact?.account_data_id
            : "";
        }
        if (accountDataId) {
          this.users[index].entity_data_id = accountDataId;
        }
      }
    },
    onChangeContactType(index) {
      this.filterVal = "";
      if (!this.users[index].contact_type) {
        this.users[index].name = "";
        return;
      }
      const userFields = this.elements.filter(
        (e) => e.entity_id && e.selected_user == this.users[index].value
      );

      if (userFields && userFields.length) {
        this.$confirm(
          `Entity fields are used. If you change the contact type related entity fields will be changed to custom fields?`,
          "Warning",
          {
            confirmButtonText: "Continue",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )

          .then(() => {
            this.resetElements(this.users[index].value);
            if (!this.users) {
              this.users[index].email = "";
            }
            this.selectRecipient.contact_type =
              this.users[index].temp_contact_type;
            this.fillRoleNameData(index, true);
          })
          .catch(() => {});
      } else {
        if (this.users[index].email) {
          this.updatedAccountDataId(index);
        }
        if (!this.users) {
          this.users[index].email = "";
        }
        this.selectRecipient.contact_type = this.users[index].contact_type;
        this.fillRoleNameData(index, true);
      }
    },

    resetElements(value) {
      this.elements = this.elements.map((e) => {
        if (
          e.selected_user == value &&
          e.field_type == "ENTITY_FIELD" &&
          e.template_id
        ) {
          e.selected_user = "";
          e.field_type = "CUSTOM_FIELD";
          delete e.template_id;
          delete e.entity_id;
          delete e.indexKey;
        }
        return e;
      });
    },
    deleteElements(value) {
      this.elements = this.elements.filter((e) => {
        if (e.selected_user != value && e.filled_by != value) {
          return e;
        }
      });
    },
    deleteCkEditorFields(value) {
      const fieldsToRemove = this.ckEditorFields.filter(
        (e) => e.filled_by === value
      );
      fieldsToRemove.forEach((field) => {
        const label = field.label;
        const regex = new RegExp(`\\[\\[${label}\\]\\]`, "g");
        if (regex.test(this.ckEditorContent)) {
          this.ckEditorContent = this.ckEditorContent.replace(regex, "");
        }
      });
      this.ckEditorFields = this.ckEditorFields.filter(
        (e) => e.filled_by !== value
      );
      // if (
      //   this.getConfigurableDocumentDataById &&
      //   this.getConfigurableDocumentDataById.ckeditor_fields &&
      //   this.getConfigurableDocumentDataById.ckeditor_fields.length
      // ) {
      //   this.getConfigurableDocumentDataById.ckeditor_fields =
      //     this.ckEditorFields;
      // }
      // if (
      //   this.getConfigurableDocumentDataById &&
      //   this.getConfigurableDocumentDataById.ck_editor_content
      // ) {
      //   this.getConfigurableDocumentDataById.ck_editor_content =
      //     this.ckEditorContent;
      // }
      bus.$emit('userDeleted',{
        isUserDeleted : true
      })
    },
    async fillRoleNameData(index, forceReset) {
      let Rolename = this.users[index].name;
      if (Rolename && !forceReset) {
        return;
      }
      let selectedContactType = this.users[index].contact_type;

      let newContacttype =
        selectedContactType && selectedContactType._id
          ? selectedContactType._id
          : selectedContactType;

      let contactType = this.getAllContactTypes.find(
        (contact) => contact && contact._id == newContacttype
      );
      // if (!contactType || !contactType.length) {
      //   this.docUsersLoading = true;
      //   await this.$store.dispatch("contactTypes/createContactType", {
      //     name: selectedContactType,
      //   });
      //   await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
      //     get_all: true,
      //     from_contacts: true,
      //     type:'CONTACT_TYPE'
      //   });
      //   this.docUsersLoading = false;
      //   contactType = this.getAllContactTypes.filter(
      //     (contact) => contact && contact.name == selectedContactType
      //   );
      // } else {
      //   this.users[index].contact_type = contactType[0]._id;
      // }
      let namesData = this.users.filter(
        (user) =>
          user && user.name && user.name.split("-")[0] == contactType.name
      );
      let maxLength = 1;
      if (namesData && namesData.length) {
        namesData.forEach((user) => {
          if (
            user.name &&
            user.name.split("-")[1] &&
            parseInt(user.name.split("-")[1]) >= maxLength
          ) {
            maxLength = parseInt(user.name.split("-")[1]) + 1;
          }
        });
      }
      Rolename = contactType.name + "-" + maxLength;
      this.users[index].name = Rolename;
    },
    async deleteItem(index) {
      this.users.splice(index, 1);
    },
    selectFocus(e, index) {
      this.selectedUserIndex = index;
    },
    handleEmailFilter(filterVal) {
      this.users[this.selectedUserIndex].email = filterVal;
      if (filterVal) {
        this.userTypeContactsFiltered = this.getUserTypeContacts.filter(
          (contact) => contact.email.includes(filterVal)
        );
        this.filterVal = filterVal;
      } else {
        this.userTypeContactsFiltered = this.getUserTypeContacts;
        this.filterVal = "";
      }
    },
    setAllContacts() {
      this.userTypeContactsFiltered = this.getUserTypeContacts;
    },
    onEmailLostFocus(e, index, email) {
      let emailRe =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.showAddContact && emailRe.test(email)) {
        this.fillrecipientEmailData(index, true);
      }
    },
    selectchange(e) {},
    fillrecipientEmailData(index, isFromBlur = false) {
      let isAlreadyTakenEmail = false;
      if (
        this.users[index] &&
        this.users[index].email &&
        typeof this.users[index].email === "string"
      ) {
        if (
          this.users[index].value == "SENDER" &&
          this.users[index].email != this.getAuthenticatedUser.email
        ) {
          this.users[index].email = this.getAuthenticatedUser.email;
          return;
        }
        this.users.forEach((user, i) => {
          if (i != index && user.email == this.users[index].email) {
            return (isAlreadyTakenEmail = true);
          }
        });
        if (
          this.users[index] &&
          this.getAuthenticatedUser &&
          this.users[index].email &&
          this.getAuthenticatedUser.email &&
          this.users[index].email.toLowerCase() ==
            this.getAuthenticatedUser.email.toLowerCase()
        ) {
          this.$message({
            message: "You Cannot Add Sender Email!.",
            type: "warning",
          });
          this.users[index].email = "";
        } else if (isAlreadyTakenEmail) {
          this.$message({
            message: "This email is already taken!.",
            type: "error",
          });
          this.users[index].email = "";
        } else {
          const userHasFields = this.userHasFields(this.users[index].value);
          var curEmail = this.users[index].email.toLowerCase();
          if (isFromBlur && curEmail.trim().length < 1) {
            curEmail = this.filterVal;
          }
          let contact = this.getAllContacts.data.filter(
            (contact) =>
              contact.email && contact.email.toLowerCase() == curEmail
          );
          if (contact && contact.length) {
            let contactTypes = this.getContactTypesFromContact(contact[0]);
            let id = this.users[index].contact_type
              ? this.users[index].contact_type._id
                ? this.users[index].contact_type._id
                : this.users[index].contact_type
              : "";
            if (
              (contactTypes.length &&
                contactTypes
                  .flatMap((el) => el?.contact_type?._id || el.contact_type)
                  .indexOf(id) != -1) ||
              this.getStandardContactType?._id == id ||
              !id
            ) {
              this.users[index].email = curEmail;
              if (!this.users[index].first_name) {
                this.$set(
                  this.users[index],
                  "first_name",
                  contact[0].first_name
                );
              } else {
                this.users[index].first_name = contact[0].first_name;
              }
              if (!this.users[index].last_name) {
                this.$set(this.users[index], "last_name", contact[0].last_name);
              } else {
                this.users[index].last_name = contact[0].last_name;
              }
              if (!this.users[index].middle_name) {
                this.$set(
                  this.users[index],
                  "middle_name",
                  contact[0].middle_name
                );
              } else {
                this.users[index].middle_name = contact[0].middle_name;
              }
              if (!this.users[index].address) {
                this.$set(this.users[index], "address", contact[0].address);
              } else {
                this.users[index].address = contact[0].address;
              }
              if (!this.users[index].company_name) {
                this.$set(
                  this.users[index],
                  "company_name",
                  contact[0].company_name
                );
              } else {
                this.users[index].company_name = contact[0].company_name;
              }
              if (this.users[index].contact_type) {
                this.updatedAccountDataId(index);
              }
              if (!this.users[index].phone) {
                this.$set(this.users[index], "phone", contact[0].phone_number);
              } else {
                this.users[index].phone = contact[0].phone_number;
              }
              if (!this.users[index].title) {
                this.$set(this.users[index], "title", contact[0].title);
              } else {
                this.users[index].title = contact[0].title;
              }
              this.users[index].contact = contact[0]._id;
              this.users[index].contact_id = contact[0]._id;
              // this.users[index].temp_contact_type =
              //   contact[0].contact_type.name;
              // this.fillRoleNameData(index);
            } else {
              this.contactTypeAlertMethod(index, contact[0]);
            }
          } else {
            if (!this.alertShown) {
              this.alertShown = true;
              this.$confirm(
                `This email is not in your contacts. Do you want to create a new contact?`,
                "Warning",
                {
                  confirmButtonText: "Create",
                  cancelButtonText: "Cancel",
                  type: "warning",
                }
              )
                .then(() => {
                  this.alertShown = false;
                  this.newContactCheck = true;
                  this.resetNewUserData();
                  this.newUser.email = this.users[index].email;
                  this.newUser.first_name = this.users[index].first_name;
                  this.newUser.last_name = this.users[index].last_name;
                  this.newUser.contact_type = this.users[index].contact_type;
                  this.selectedUserIndex = -1;
                  this.selectedUserIndex = index;
                  this.contactTypes = [];
                  this.selectedContact = null;
                  // this.checkIsDisabled = false;
                  const userHasFields = this.userHasFields(
                    this.users[index].value
                  );
                  this.checkIsDisabled = this.users[index].contact_type
                    ? true
                    : false;
                  this.showAddContact = true;
                })
                .catch(() => {
                  this.alertShown = false;
                  this.users[index].email = "";
                  this.users[index].first_name = "";
                  this.users[index].last_name = "";
                  //this.users[index].name = "";
                  this.setAllContacts(null);
                });
            }
          }
        }
      }
    },
    contactTypeAlertMethod(index, contact) {
      let msg = "Specified contact";
      if (this.users[this.selectedUserIndex].email) {
        msg = this.users[this.selectedUserIndex].email;
      }
      msg = msg + " is not associated with ";
      if (this.users[this.selectedUserIndex].contact_type) {
        let found = this.getAllContactTypes.find(
          (e) => e._id == this.users[this.selectedUserIndex].contact_type
        );
        if (found?.name) {
          msg = msg + found.name;
        } else {
          msg = msg + "specified contact type.";
        }
      } else {
        msg = msg + "specified contact type.";
      }
      msg = msg + " Please edit your contact.";
      this.$confirm(msg, "Warning", {
        confirmButtonText: "Edit",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.contactTypes = [];
          this.selectedContact = contact._id;
          this.selectedUserIndex = -1;
          this.selectedUserIndex = index;
          this.newUser.email = this.users[index].email;
          this.newUser.first_name = contact.first_name;
          this.newUser.last_name = contact.last_name;
          this.newUser.title = contact.title;
          this.newUser.middle_name = contact.middle_name;
          this.newUser.address = contact.address;
          this.newUser.company_name = contact.company_name;
          this.newUser.phone = contact.phone_number;
          this.newUser.contact_type = this.users[index].contact_type;
          this.newUser.account_data_id = this.users[index].entity_data_id;
          this.contactTypes = [...this.getContactTypesFromContact(contact)];
          this.showAddContact = true;
        })
        .catch(() => {
          this.users[index].email = "";
          this.users[index].first_name = "";
          this.users[index].last_name = "";
          // this.users[index].name = "";
          this.setAllContacts(null);
        });
    },

    resetForm() {
      this.showNewUserForm = false;
      this.selectedUser = null;
      this.selectRecipient.email = "";
      this.selectRecipient.user_type = "SIGNER";
      this.selectRecipient.contact_type = "";
      this.selectRecipient.name = "";
      this.resetSelectedReceiver();
      this.resetNewUserData();
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.users.map((user) => {
            if (user) {
              if (this.getAllContacts.data) {
                let isExists = this.getAllContacts.data.forEach((user) => {
                  if (user.email == this.users[index].email) {
                    return true;
                  } else {
                    return false;
                  }
                });
                if (!isExists) {
                  this.createNewUser();
                }
              }
              this.updateReceipentUser();
            } else {
              this.addContact();
              // this.addReceiver();
            }
          });
        } else {
          return false;
        }
      });
    },
    validateNumber: (event) => {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    Addrecipient() {
      this.isAdd = true;
    },
    iscurrentEdit(index) {
      this.isEdit = true;
      this.selectedUserIndex = index;
    },
    getContactTypeData() {
      let contactType = "";
      if (
        this.getAllContactTypes &&
        this.getAllContactTypes.length &&
        this.getAllContactTypes.length == 1
      ) {
        contactType = this.getAllContactTypes[0];
        contactType = this.selectRecipient.contact_type;
      }

      return contactType;
    },

    addReceiver() {
      try {
        this.is_Submit = false;
        this.filterVal = "";
        let exst = this.users
          .filter((user) => user.color)
          .map((user) => user.color);
        // getting random color for user fields
        let userColor = this.getRandomColor(exst);
        let contactType = this.getContactTypeData(
          this.selectRecipient.contact_type
        );
        let maxValue = this.users.length;
        this.users.forEach((user) => {
          if (
            user.value &&
            user.value.split("_")[1] &&
            parseInt(user.value.split("_")[1]) >= maxValue
          ) {
            maxValue = parseInt(user.value.split("_")[1]) + 1;
          }
        });
        this.users.push({
          default: false,
          name: this.newUser.name,
          value: "RECEIVER_" + maxValue,
          type: "RECEIVER",
          e_signature_fields: [],
          e_signature_date_fields: [],
          e_signature_order: maxValue + 1,
          e_signature_required: false,
          has_approval_access: this.newUser.has_approval_access,
          email: "",
          first_name: this.newUser.first_name,
          last_name: this.newUser.last_name,
          title: this.newUser.title,
          color: userColor,
          phone: this.newUser.phone,
          address: this.newUser.address,
          company_name: this.newUser.company_name,
          is_cc: false,
          user_type: this.selectRecipient.user_type,
          contact: null,
          is_update_contact: false,
        });
        this.resetForm();
      } catch (err) {}
      // document.getElementsById(`user_id`).focus();
      // this.scrollToEnd();
      setTimeout(() => this.scrollToEnd(), 500);
    },
    // scrollToEnd() {
    //   var container = document.querySelector(".scroll");
    //   var scrollHeight = container.scrollHeight;
    //   container.scrollTop = scrollHeight;

    // },

    resetNewUserData() {
      this.newUser.email = "";
      this.newUser.first_name = "";
      this.newUser.last_name = "";
      this.newUser.phone = "";
      this.newUser.address = "";
      this.newUser.company_name = "";
      this.newUser.name = "";
      this.newUser.user_type = "SIGNER";
      this.newUser.contact_type = "";
      this.showNewUserForm = false;
    },
    onlyNumbers(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        event.preventDefault();
      }
    },
    async updateContact(index, id) {
      try {
        let selectedContact = this.getSelectedContact(this.users[index].email);
        let contactTypes = this.getContactTypesFromContact(selectedContact);
        let params = {
          first_name: this.users[index].first_name,
          last_name: this.users[index].last_name,
          name: this.users[index].name,
          email: this.users[index].email,
          contact_type: selectedContact?.contact_type?._id
            ? selectedContact.contact_type._id
            : selectedContact.contact_type,
          phone_number: this.users[index].phone,
          address: this.users[index].address,
          company_name: this.users[index].company_name,
          title: this.users[index].title,
          contact_types: contactTypes,
          middle_name: this.users[index].middle_name,
        };
        /* if (this.users[index].name) {
          params.name = this.users[index].name;
        }
        if (this.users[index].company_name) {
          params.company_name = this.users[index].company_name;
        }
        if (this.users[index].contact) {
          params.contact = this.users[index].contact;
        }

        if (this.users[index].user_type) {
          params.user_type = this.users[index].user_type;
        }
        if (this.users[index].address) {
          params.address = this.users[index].address;
        }
        if (this.users[index].title) {
          params.title = this.users[index].title;
        }
        if (this.users[index].phone) {
          let re =
            /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
          if (re.test(this.users[index].phone)) {
            params.phone_number = this.users[index].phone;
          }
        }*/
        await this.$store.dispatch("contacts/updateContact", {
          contact_id: id,
          contact: params,
        });
        this.is_update_contact = true;
        if (this.getUpdateContactStatus) {
          this.$notify.success({
            title: "Success",
            message: "Contact updated successfully",
          });
        } else if (this.getContactErrors && this.getContactErrors.length) {
          this.$notify.error({
            title: "Error",
            message: "Error in updating Contact",
          });
        }
      } catch (err) {
        this.loading = false;
      }
    },

    editReceiver(user, index) {
      this.selectedUser = { ...user };
      this.newUser.email = this.selectedUser.email;
      this.newUser.name = this.selectedUser.name;
      this.newUser.first_name = this.selectedUser.first_name;
      this.newUser.last_name = this.selectedUser.last_name;
      this.newUser.phone = this.selectedUser.phone;
      this.newUser.address = this.selectedUser.address;
      this.newUser.company_name = this.selectedUser.company_name;
      this.newUser.has_approval_access = this.selectedUser.has_approval_access;
      this.newUser.is_cc = this.selectedUser.is_cc;
      this.newUser.user_type = this.selectedUser.user_type;
      this.newUser.contact_type =
        this.selectedUser.contact_type && this.selectedUser.contact_type.name
          ? this.selectedUser.contact_type.name
          : this.selectedUser.contact_type;
      this.newUser.contact = this.selectedUser.contact;

      if (this.selectedUser.type == "SENDER") {
        // this.showNewUserForm = true;
      }
      this.selectRecipient.email = this.selectedUser.email;
      this.selectRecipient.name = this.selectedUser.name;
      this.selectRecipient.user_type = this.selectedUser.user_type;
      this.selectRecipient.contact_type =
        this.selectedUser.contact_type && this.selectedUser.contact_type.name
          ? this.selectedUser.contact_type.name
          : this.selectedUser.contact_type;
      this.selectedUserIndex = index;
    },
    updateSender() {
      this.users[this.selectedUserIndex].user_type =
        this.selectRecipient.user_type;
      this.users[this.selectedUserIndex].first_name = this.newUser.first_name;
      this.users[this.selectedUserIndex].last_name = this.newUser.last_name;
      this.resetForm();
    },

    updateReceipentUser() {
      /*let contactType = this.getContactTypeData(
        this.selectRecipient.contact_type
      );*/
      this.users[this.selectedUserIndex].first_name = this.newUser.first_name;
      this.users[this.selectedUserIndex].last_name = this.newUser.last_name;
      this.users[this.selectedUserIndex].email = this.newUser.email;
      this.users[this.selectedUserIndex].phone = this.newUser.phone;
      this.users[this.selectedUserIndex].address = this.newUser.address;
      this.users[this.selectedUserIndex].company_name =
        this.newUser.company_name;
      this.users[this.selectedUserIndex].name = this.selectRecipient.name;
      this.users[this.selectedUserIndex].user_type =
        this.selectRecipient.user_type;
      this.users[this.selectedUserIndex].is_cc = this.selectRecipient.is_cc;
      this.users[this.selectedUserIndex].contact_type = this.contactType;

      this.resetForm();
    },
    resetSelectedReceiver() {
      this.selectedUser = null;
      this.selectedUserIndex = null;
      this.showNewUserForm = false;
    },
    moveDown(index) {
      if (index == this.users.length - 1) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.users);
      this.users = [];
      this.users = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.users);
      this.users = [];
      this.users = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;

      return signaturedUsers;
    },
    /* isSubmit
      this.$emit("close", {users:this.users});*/
    closeSettings() {
      this.$emit("close", {
        close: true,
      });
    },
    checkUsersData() {
      let emailRe =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let NameRe = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].?\d|\d.?[A-Za-z]){1,}/;
      let phoneRe = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      let errorContact = this.users.find((el, index) => {
        if (el.type != "SENDER") {
          return (
            !emailRe.test(el.email) ||
            !NameRe.test(el.first_name) ||
            (el && el.phone && !phoneRe.test(el.phone)) ||
            !el.last_name ||
            !el.name ||
            parseInt(el.title) ||
            !this.checkRoleDuplicate(el.name ? el.name : "", index) ||
            (!el.contact_type && el.type != "SENDER")
          );
        } else {
          return (
            !emailRe.test(el.email) ||
            !NameRe.test(el.first_name) ||
            !el.last_name ||
            !el.name
          );
        }
      });
      let approvers = 0;
      let signers = 0;
      let errors = 0;
      this.users.forEach((user, index) => {
        if (user && user.phone && !phoneRe.test(user.phone)) {
          this.activeAddMore.push(index);
          errors++;
        }
        if (user && user.title && parseInt(user.title)) {
          this.activeAddMore.push(index);
          errors++;
        }
        if (user && user.user_type == "APPROVER") {
          approvers++;
        }
        if (user && user.user_type == "SIGNER") {
          signers++;
        }
      });
      if (errorContact || errors > 0 || (approvers == 0 && signers == 0)) {
        if (approvers == 0 && signers == 0) {
          this.$message({
            message: "Warning, Atleast one signer or one approver must be add.",
            type: "warning",
          });
        }
        this.is_Submit = true;
        return false;
      }
      return true;
    },
    prepareDocumentUsers(users) {
      let signUsers = this.allElements
        .filter((element) => element.type == "SIGNATURE")
        .flatMap((element) => element.filled_by);
      let requiredUsers = this.allElements.flatMap((el) => el.filled_by);
      return users.map((user, index) => {
        user.e_signature_order = index;
        if (signUsers.indexOf(user.value) != -1) {
          user.e_signature_required = true;
        } else {
          user.e_signature_required = false;
        }
        if (requiredUsers.indexOf(user.value) != -1) {
          user.fields_required = true;
        } else {
          user.fields_required = false;
        }
        if (user && user.user_type == "APPROVER") {
          user.has_approval_access = true;
        }
        if (user && user.user_type == "CC") {
          user.is_cc = true;
        }
        if (user && user.user_type != "APPROVER" && user.user_type != "CC") {
          user.has_approval_access = false;
        } else {
          user.is_cc = false;
        }
        if (user && user.type != "SENDER" && this.getAllContactTypes) {
          // let contactType = this.getAllContactTypes.find((contact) => {
          //   if (
          //     user.temp_contact_type &&
          //     contact.name == user.temp_contact_type
          //   ) {
          //     return contact;
          //   }
          // });
          let contactEmails = this.getAllContacts.data.flatMap((element) =>
            element.email ? element.email.toLowerCase() : element.email
          );
          if (contactEmails.indexOf(user.email.toLowerCase()) == -1) {
            this.addcontacts(index);
          }
          // user.contact_type =
          //   contactType && contactType._id ? contactType._id : null;
        } else {
          user.user_id = this.getAuthenticatedUser._id;
        }
        return user;
      });
    },
    async updateConfigDocumentUsers(params) {
      await this.$store.dispatch(
        "configurableDocuments/updateConfigurableDocument",
        params
      );
      if (this.getConfigurableDocumentUpdateStatus) {
        this.$notify.success({
          title: "Success",
          message: "Recipients saved successfully",
        });
        this.$emit("close", {
          elements: this.elements,
          submit: this.isSubmit,
        });
        this.docUsersLoading = false;
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in adding recipients",
        });
        this.docUsersLoading = false;
      }
    },
    async updateEmployeeDocumentUsers(params) {
      try {
        params.employee_document_id = this.$route.params.employee_document_id;
        if (this.files) {
          var formData = new FormData();
          this.files.forEach((file, index) => {
            if (index == 0) {
              this.fieldsFormUpload.selectedFile =
                file.raw !== undefined ? file.raw : file;
              this.fieldsFormUpload.selectedFileName =
                file.raw !== undefined ? file.raw.name : file.name;
              this.fieldsFormUpload.title =
                file.raw !== undefined ? file.raw.name : file.name;
            }
            formData.append(
              "uploadedFiles",
              file.raw !== undefined ? file.raw : file
            );
          });
          formData.append(
            "employee_document_id",
            this.$route.params.employee_document_id
          );
          formData.append(
            "document_users",
            JSON.stringify(params.document_users)
          );
          formData.append(
            "enforce_signature_order",
            params.enforce_signature_order
          );
          formData.append(
            "is_anyone_can_approve",
            params.is_anyone_can_approve
          );
          formData.append("id", params.id);
          params = formData;
        }
        params.ck_editor_content = this.ckEditorContent;
        params.ckeditor_fields = this.ckEditorFields;
        await this.$store.dispatch("documents/updateDraftDocument", params);
        if (this.getDraftDocumentUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Recipients added successfully",
          });
          this.$emit("close", {
            submit: this.isSubmit,
            elements: this.elements,
          });
          this.docUsersLoading = false;
        } else {
          if (
            this.getDraftDocumentUpdateErrors &&
            this.getDraftDocumentUpdateErrors.critical_error &&
            this.getDraftDocumentUpdateErrors.critical_error ==
              "Permission Denied. Please Contact Owner"
          ) {
            this.$notify.error({
              title: "Error",
              message:
                "Permission denied for edit draft document. Please contact owner",
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error in adding recipients",
            });
          }
          this.docUsersLoading = false;
        }
      } catch (error) {}
    },
    // contactUpdationCheck(){
    //   this.getAllContacts.data
    // },
    async saveUsers() {
      let checkusers = this.checkUsersData();
      if (checkusers) {
        let configuredUsers = this.prepareDocumentUsers(this.users);
        this.docUsersLoading = true;
        let params = {
          document_users: configuredUsers,
          enforce_signature_order: this.enforceSignatureOrderValue,
          is_anyone_can_approve: this.isAnyoneCanApproveValue,
          id: this.$route.params.configurable_document_id,
        };
        if (this.$route.name == "edit-configure-document-image") {
          this.updateConfigDocumentUsers(params);
        } else {
          this.updateEmployeeDocumentUsers(params);
        }
        this.getAllContacts.data.forEach((contact) => {
          this.users.forEach((user, index) => {
            if (
              user.type != "SENDER" &&
              user.email == contact.email &&
              (user.first_name != contact.first_name ||
                user.last_name != contact.last_name ||
                user.company_name != contact.company_name ||
                user.phone != contact.phone_number ||
                user.middle_name != contact.middle_name ||
                user.address != contact.address ||
                user.title != contact.title ||
                !contact.first_name ||
                !contact.last_name)
            ) {
              this.updateContact(index, contact._id);
            }
          });
        });
      }
      //}
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    async addcontacts(index) {
      let params = {
        first_name: this.users[index].first_name,
        last_name: this.users[index].last_name,
        email: this.users[index].email,
        contact_type: this.users[index].temp_contact_type,
        phone_number:
          this.users[index] && this.users[index].phone
            ? this.users[index].phone
            : "",
        address:
          this.users[index] && this.users[index].address
            ? this.users[index].address
            : "",
        company_name:
          this.users[index] && this.users[index].company_name
            ? this.users[index].company_name
            : "",
        title:
          this.users[index] && this.users[index].title
            ? this.users[index].title
            : "",
      };
      await this.$store.dispatch("contacts/createContact", params);
      if (this.getCreateContactStatus && this.getCreateContactData) {
        // this.newUser.contact = this.getCreateContactData._id;
        // await this.$store.dispatch("contacts/fetchAllContacts", {
        //   get_all: true,type:'CONTACT'
        // });
        // if(this.getAllContacts && this.getAllContacts.data && this.getAllContacts.data[0] && this.getAllContacts.data[0]._id){
        this.users[index]["contact"] = this.getCreateContactData._id;
        // }
        this.$notify.success({
          title: "Success",
          message: "Contact created successfully",
        });
      }
    },
    deleteReceiver(index) {
      if (confirm) {
        this.$confirm(
          "All fields associated with this recipient will be deleted. Are you sure you want to delete the recipient ? ",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }
        ).then(async () => {
          // if (!this.hasDeleteAccess(this.users[index])) {
          this.deleteElements(this.users[index].value);
          if (this.openEditor) {
            this.deleteCkEditorFields(this.users[index].value);
          }
          await this.users.splice(index, 1);
          this.$notify.success({
            title: "Success",
            message: "Recipient Deleted Successfully",
          });
          // }
          // else {
          //   this.$message({
          //     message: "User has fields so you Can't delete user.",
          //     type: "warning",
          //   });
          // }
        });
      }
    },
    getEignaturePriority(userName) {
      let index = this.users.findIndex((user) => user.value == userName);
      if (index > -1) {
        return index + 1;
      } else {
        return 0;
      }
    },
    handleApprovalUsers(approvedUser) {
      this.users = this.users.map((user) => {
        if (
          user.name != approvedUser.name &&
          user.has_approval_access == true
        ) {
          user.has_approval_access = false;
        }
        return user;
      });
    },
    async checkEmail() {
      try {
        let newUserDetails = this.getAllContacts.data.filter(
          (user) => user.email === this.selectRecipient.email
        );
        if (
          newUserDetails &&
          newUserDetails.length &&
          newUserDetails[0].contact_type &&
          newUserDetails[0].contact_type.name !=
            this.selectRecipient.contact_type
        ) {
          this.$confirm(
            "This email was already linked to another Contact Type?",
            "Warning",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          )
            .then(async () => {
              this.addNewDocumentUser();
            })
            .catch(() => {
              //
            });
        } else if (!newUserDetails || !newUserDetails.length) {
          this.$confirm("Are you sure to create a new Contact?", "Warning", {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          })
            .then(async () => {
              this.addNewDocumentsUser();
            })
            .catch(() => {
              //
            });
        } else {
          await this.addNewDocumentUser();
        }
      } catch (err) {}
    },
    addNewDocumentsUser() {
      this.newUser.email = this.selectRecipient.email;
      this.showNewUserForm = true;
    },
    async addNewDocumentUser() {
      try {
        let newUserDetails = this.getAllContacts.data.filter(
          (user) => user.email === this.selectRecipient.email
        );
        let name = this.selectRecipient.name;
        if (!name) {
          let namesData = this.users.filter(
            (user) => user && user.name.split("-")[0] == selectedContactType
          );
          let maxLength = 1;
          if (namesData && namesData.length) {
            namesData.forEach((user) => {
              if (
                user.name &&
                user.name.split("-")[1] &&
                parseInt(user.name.split("-")[1]) >= maxLength
              ) {
                maxLength = parseInt(user.name.split("-")[1]) + 1;
              }
            });
          }
          name = newUserDetails[0].contact_type.name + "-" + maxLength;
        }
        if (newUserDetails && newUserDetails.length) {
          this.newUser.email = newUserDetails[0].email.toLowerCase();
          this.newUser.first_name = newUserDetails[0].first_name;
          this.newUser.last_name = newUserDetails[0].last_name;
          this.newUser.phone = newUserDetails[0].phone_number || "";
          this.newUser.address = newUserDetails[0].address || "";
          this.newUser.company_name = newUserDetails[0].company_name || "";
          this.newUser.name = name;
          if (this.selectedUser) {
            await this.updateReceipentUser();
          } else {
            await this.addReceiver();
          }
          this.selectRecipient.email = "";
          this.selectRecipient.name = "";
          this.selectRecipient.user_type = "SIGNER";
          this.selectRecipient.contact_type = "";
        }
      } catch (err) {}
    },
    //create new user
    async createNewUser() {
      await this.$store.dispatch("contacts/createContact", this.newUser);
      if (this.getCreateContactStatus) {
        this.newUser.contact = this.getCreateContactData._id;
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          from_contacts: true,
          type: "CONTACT_TYPE",
        });
        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,
          type: "CONTACT",
        });
        this.$notify.success({
          title: "Success",
          message: "Contact created successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in creating Contact",
        });
      }
    },
    // contact save
    async addContact() {
      try {
        this.docUsersLoading = true;
        this.newUser.phone_number = this.newUser.phone;
        this.newUser.email = this.newUser.email.toLowerCase();
        await this.$store.dispatch("contacts/createContact", this.newUser);

        if (this.getCreateContactStatus) {
          this.newUser.contact = this.getCreateContactData._id;
          await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
            get_all: true,
            from_contacts: true,
            type: "CONTACT_TYPE",
          });
          await this.$store.dispatch("contacts/fetchAllContacts", {
            get_all: true,
            type: "CONTACT",
          });
          this.$notify.success({
            title: "Success",
            message: "Contact created successfully",
          });
          this.addReceiver();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in creating Contact",
          });
        }
        this.docUsersLoading = false;
      } catch (err) {
        this.docUsersLoading = false;
        this.loading = false;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("contacts/setAllContacts", null, { root: true });
    this.$store.commit("contacts/setCreateContactStatus", null, { root: true });
    this.$store.commit("contacts/setUpdateContactStatus", null, { root: true });
    this.$store.commit("contacts/setContactErrors", null, { root: true });
    // this.$store.commit( "contacts/setCreateContactData",null,{root:true})
    // this.$store.commit( "contacts/setContactTypeCreateStatus",null,{root:true})
    // this.$store.commit( "contactTypes/setAllContactTypes",null,{root:true})
    // this.$store.commit( "contactTypes/setContactTypeCreateStatus",null,{root:true})
    this.$store.commit("documents/setDocumentUsersStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setEmployeeDocumentUsers", null, {
      root: true,
    });
    this.$store.commit("documents/setDraftDocumentUpdateStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDraftDocumentUpdateErrors", null, {
      root: true,
    });
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentUpdateStatus",
      null,
      { root: true }
    );
    // this.$store.commit("entities/setCompanyEntityDetails",null,{root:true})
    this.$store.commit("company/setListOfCompanies", null, {
      root: true,
    });
  },
};
</script>

<style lang="scss">
.addrecipientpage {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
  .el-dialog__body {
    overflow-y: hidden !important;
    max-height: 100vh !important;
  }
}
/*
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: unset !important;
}
*/

.el-input__inner {
  outline-color: #fff;
  // height: 60px;
}
.my-class .el-input__inner {
  height: 60px !important;
}
.document-users-order {
  .users-header {
    display: grid;
    padding: 10px 0px 0px 10px;
    grid-template-columns: 20px 25px 120px minmax(80px, 1fr) minmax(180px, 1fr) 150px 100px 100px 100px 40px 40px;
    align-items: left;
    grid-gap: 15px;
    justify-content: center;
    & > div {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
  .main {
    display: flex;
    background-color: #f7f7f7;
  }
  .AddMore {
    background-color: #000000;
    text-align: center;
    align-content: center;
    margin-left: 8px;
  }
  .addstyle {
    margin-left: 10px;
  }
  .checkbox {
    margin-left: 10px;
    margin-top: 45px;
  }
  .list-scrollable {
    height: calc(100vh - 520px);
    overflow: scroll;
    border-top: 1px solid #efefef;
    padding: 4px 10px;
  }
  .el-scrollbar {
    height: calc(100vh - 65px);
  }
  .each-user {
    cursor: pointer;
    position: relative;
    border: 1px dashed #dddddd;
    padding: 10px 0px 10px 20px;
    display: grid;
    grid-template-columns: 20px 20px 120px minmax(80px, 1fr) minmax(180px, 1fr) 150px 100px 100px 100px 40px 40px;
    align-items: center;
    gap: 15px;
    margin: 1em 0;
    text-align: left;
    justify-content: center;
    background-color: #fbfbfb;
    border-radius: 3px;
    .btn-delete {
      position: absolute;
      top: 13px;
      right: 13px;
    }
  }
  .addrecipientpage {
    .el-dialog__body {
      background-color: #f7f7f7;
    }
  }

  .priority {
    font-size: 1.1em;
    padding: 5px;
    margin-right: 5px;
  }

  .scroll {
    // max-height: 390px;
    // overflow-y: scroll;
  }

  .directions {
    padding: 5px;
    cursor: pointer;
  }

  .flip-list-move {
    transition: transform 1s;
  }

  .select-recipients {
    margin-bottom: 3em;

    .heading {
      @media (max-width: 991.98px) {
        margin-left: 30px !important;
      }
    }

    .scroll {
      @media (max-width: 991.98px) {
        padding-left: 0px !important;
        padding-right: 0px !important;

        .el-row-wrapper {
          margin-left: 30px !important;
        }
      }

      @media (max-width: 575.98px) {
        padding-left: 0px !important;
        padding-right: 0px !important;

        .el-row-wrapper {
          margin-left: 0px !important;
        }
      }
    }

    .new-contact-btn {
      position: absolute;
      right: 0;
    }

    .el-row-wrapper {
      @media (max-width: 991.98px) {
        width: 94% !important;
      }

      @media (max-width: 575.98px) {
        width: 97% !important;
      }

      padding-left: 10px;

      .AddMore {
        div[role="button"] {
          padding-left: 5px;
          height: 30px;

          p {
            margin-top: 16px;
          }
        }

        margin-top: 0px !important;
        margin-bottom: 2px !important;

        @media (max-width: 991.98px) {
          margin-bottom: 0px !important;
        }

        div[role="tab"] {
          // padding-right: 10px !important;
          // background-color: rgb(247, 247, 247);
        }
      }

      .el-row {
        .recp-el-col {
          .el-form-item {
            margin-bottom: 10px !important;

            @media (max-width: 991.98px) {
              margin-bottom: 5px !important;
            }

            .el-form-item__label {
              line-height: 25px;
            }

            .el-form-item__content {
              a {
                line-height: 25px !important;
              }
            }
          }

          &:last-child {
            @media (max-width: 767.98px) {
              .el-icon-circle-close {
                margin-top: 0px !important;
              }
            }
          }
        }

        @media (max-width: 767.98px) {
          padding-right: 10px !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: unset !important;
}

.mtb10 {
  margin-bottom: 10px;
}

.mtb13 {
  margin-bottom: 15px;
}

.error {
  color: red;
}
</style>

<style lang="scss">
.mobile {
  .outersize-confirm-type-two {
    width: 100% !important;
    left: 0px !important;

    .el-dialog {
      width: 96% !important;
    }
  }
}
</style>
